import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import { TopNav } from "./top-nav";

const lifecycles = singleSpaReact({
    React,
    ReactDOM,
    rootComponent: TopNav,
    errorBoundary(err, info, props) {
      // Customize the root error boundary for your microfrontend here.
      return null;
    },
  });

  export const { bootstrap, mount, unmount } = lifecycles;